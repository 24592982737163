<template>

    <div class="h-auto w-full rounded-lg module p-2 flex flex-col mb-2">

        <div class="h-5 flex flex-row justify-start items-center px-1">

            <div class="h-full w-9/12 flex flex-row justify-start items-center">
                <span class="text-aux font-semibold text-xs">{{ title }}</span>
            </div>

            <div class="h-full w-2/12 flex flex-row justify-center items-center">
                <span class="text-white font-semibold text-sm">{{ row.total.oportunity | numberFormat(0) }}</span>
            </div>

            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                <span class="text-font-gray text-xxs">{{ row.total.sales | reduceBigNumbers(2) }} €</span>
            </div>

        </div>

        <div class="rounded-lg bg-filters px-2 py-3 mt-2 flex flex-col"
             :class="{'h-25': !openWin, 'h-56': openWin}" style="transition: all .3s">
            <div class="h-auto"  @click="openWin = !openWin">
                <TotalsWin :row="row.Win" />
            </div>
            <div v-if="openWin" class="overflow-hidden flex-1 flex flex-col">
                <PipelineByPercentageWithoutSlide :sectors="row.Win" />
            </div>
        </div>

        <div class="rounded-lg bg-filters px-2 py-3 mt-2 flex flex-col"
             :class="{'h-25': !openOnGoing, 'h-56': openOnGoing}" style="transition: all .3s">
            <div class="h-auto"  @click="openOnGoing = !openOnGoing">
                <TotalsOngoing :row="row.OnGoing" />
                <TotalsByPercentage  :row="row.OnGoing"/>
            </div>
            <div v-if="openOnGoing" class="overflow-hidden flex-1 flex flex-col">
                <PipelineByPercentage :sectors="row.OnGoing" />
            </div>
        </div>

        <div class="rounded-lg bg-filters px-2 py-3 mt-2 flex flex-col"
             :class="{'h-25': !openLost, 'h-56': openLost}" style="transition: all .3s">
            <div class="h-auto"  @click="openLost = !openLost">
                <TotalsLost :row="row.Lost" />
            </div>
            <div v-if="openLost" class="overflow-hidden flex-1 flex flex-col">
                <PipelineByPercentageWithoutSlide :sectors="row.Lost" />
            </div>
        </div>

    </div>
    
</template>

<script>

const PipelineByPercentage = () => import("@/components/Mobile/Pipeline/PipelineCircle/PipelineByPercentage");
const TotalsByPercentage = () => import("@/components/Mobile/Pipeline/PipelineCircle/TotalsByPercentage");
const TotalsWin = () => import("@/components/Mobile/Pipeline/PipelineCircle/TotalsWin");
const TotalsLost = () => import("@/components/Mobile/Pipeline/PipelineCircle/TotalsLost");
const TotalsOngoing = () => import("@/components/Mobile/Pipeline/PipelineCircle/TotalsOngoing");
const PipelineByPercentageWithoutSlide = () => import("@/components/Mobile/Pipeline/PipelineCircle/PipelineByPercentageWithoutSlide");

export default {
    components: {
        PipelineByPercentage,
        TotalsByPercentage,
        TotalsWin,
        TotalsLost,
        TotalsOngoing,
        PipelineByPercentageWithoutSlide
    },
    props: ['row', 'title'],
    data() {
        return {
            openWin: false,
            openOnGoing: false,
            openLost: false
        }
    }
}
</script>